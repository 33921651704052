import AddToCart from '@danone-global/widgets/add-to-cart-button'
import Checkout from '@danone-global/widgets/checkout'
import OrderCompleted from '@danone-global/widgets/order-completed'
import OrderHistory from '@danone-global/widgets/order-history'
import Price from '@danone-global/widgets/price'
import ShoppingCart from '@danone-global/widgets/shopping-cart'
import ShoppingCartContent from '@danone-global/widgets/shopping-cart-content'
import VariantEditor from '@danone-global/widgets/variant-editor'

/**
 * Define which widgets are available on PRODUCTION
 */
 export const widgets = [
    AddToCart,
    Checkout,
    OrderCompleted,
    Price,
    OrderHistory,
    ShoppingCart,
    ShoppingCartContent,
    VariantEditor
  ]
  