import React from 'react'
import en from '../translations/en.json'
import { BaseConfig } from '@danone-global/internal/react/core'
import { messages } from '@danone-global/internal/react/modules/checkout'

export const baseEnvironment: BaseConfig = {
  storeName: 'NUTRICIA',

  siteName: 'be-nutricia',

  siteSlug: 'be-nutricia',

  defaultLocale: 'nl-BE',

  providerId: 'danis_eu',

  ctHost: 'https://api.europe-west1.gcp.commercetools.com',

  ctAuthHost: 'https://auth.europe-west1.gcp.commercetools.com',

  ctStoreKey: 'NUTRICIA',

  localeConfigs: [
    {
      country: 'BE',
      currency: 'EUR',
      initMessages: () =>
        import('../translations/nl_BE.json').then((r) => r.default),
      locale: 'nl-BE',
    },
    {
      country: 'BE',
      currency: 'EUR',
      initMessages: () =>
        import('../translations/fr_BE.json').then((r) => r.default),
      locale: 'fr-BE',
    },
  ],

  analytics: {
    tracker: null,
    language: 'nl-BE',
    defaultCategory: 'Baby food'
  },

  overrides: {
    'shopping-cart-v2': {
      variant: 'badge',
    },
  },

  passwordValidation: {
    mustHaveLowercaseAndUppercase: true,
    specialCharacters: 'DACH',
  },

  checkout: {
    stepAddress: React.lazy(
      () =>
        // imports should be on one line, in order to trigger transifex extract actions!
        // prettier-ignore
        import('@danone-global/internal/react/components/checkout/be-step-address'),
    ),
    payment: React.lazy(
      () =>
        // prettier-ignore
        import('@danone-global/internal/react/components/payment-providers/adyen-drop-in'),
    ),
  },
}
