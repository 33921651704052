import { Features } from '@danone-global/internal/react/core'

export const features: Features = {
  enableNotifyMe: true,
  enableUpdateExternalProfile: true,
  useIdentityManagementV2: true,
  hideAddToCartForGuestUser: true,
  hideBillingAddress: true,
  hidePrices: true,
  hideTrackParcelBtn: true,
  siteEnabled: true,
  siteBE: true,
}
