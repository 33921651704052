import { Config } from '@danone-global/internal/react/core'

import { baseEnvironment } from './environment.base'

export const environment: Config = {
    ...baseEnvironment,

    isProduction: true,

    isDevelopment: false,

    ctProjectKey: 'be-b2b-prd-danone',

    ctClientId: 'LITrUKg6-bfT8iiss4B3DRm2',

    ctClientSecret: 'tCfpdmGy5tzLd4QRSyi08E5EJhrqaxJ-',

    defaultChannelId: 'd9693abb-e33f-4b28-8a6a-6ad5496dfe97',

    sentryDSN: 'https://26e95ebef18de217c29264c5f256280b@o4506427613577216.ingest.sentry.io/4506433323008000',

    storeName: 'NUTRICIA',

}
